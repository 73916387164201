<template>
  <div class="banner-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>广告图管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" @click="addVisible = true">添加广告图</el-button>
      </div>
      <div class="table-area">
        <el-table :data="pageData" border>
          <el-table-column fixed prop="id" label="ID" width="70"></el-table-column>
          <el-table-column prop="sort" label="排序" width="120"></el-table-column>
          <el-table-column prop="img" label="广告图" width="120">
            <template slot-scope="scope">
              <el-image :src="scope.row.img" width="120"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="版块" width="120">
            <template slot-scope="scope">
              <el-tag type="success" effect="plain" v-if="scope.row.type === 1">版块1</el-tag>
              <el-tag type="danger" effect="plain" v-if="scope.row.type === 2">版块2</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="link" label="链接"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row.id)">编辑</el-button>
              <el-button type="text" size="small" @click="del(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 添加广告弹窗 -->
    <el-dialog title="添加广告" :visible.sync="addVisible" width="40%" top="4vh" :before-close="addClose">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="top">
        <el-form-item label="归属版块:" prop="type">
          <el-select v-model="addForm.type" placeholder="请选择归属版块">
            <el-option label="板块1" :value="1"></el-option>
            <el-option label="板块2" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number size="small" v-model="addForm.sort" :step="1" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="广告图" prop="img">
          <upload :url="addForm.img" :filed="'img'" :max-num="1" @checked-files="checkedAddFiles" @del-select="delAddSelect"></upload>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="addForm.link"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addSubmit('addRef')">确认新增</el-button>
          <el-button @click="cancellAdd">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 编辑广告弹窗 -->
    <el-dialog title="编辑广告" :visible.sync="editVisible" width="40%" top="4vh" :before-close="editClose">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="top">
        <el-form-item label="归属版块:" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择归属版块">
            <el-option label="板块1" :value="1"></el-option>
            <el-option label="板块2" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number size="small" v-model="editForm.sort" :step="1" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="广告图" prop="img">
          <upload :url="editForm.img" :filed="'img'" :max-num="1" @checked-files="checkedEditFiles" @del-select="delEditSelect"></upload>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="editForm.link"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editSubmit('editRef')">提交修改</el-button>
          <el-button @click="cancellEdit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'Banner',
  components: {
    Upload
  },
  data () {
    return {
      pageData: [],
      addForm: {
        type: 1,
        sort: 0,
        img: '',
        link: ''
      },
      addRules: {
        type: [
          { required: true, message: '请选择归属板块', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传广告图', trigger: 'blur' }
        ]
      },
      // 添加弹窗
      addVisible: false,
      editForm: {
        id: 0,
        sort: 0,
        type: 1,
        img: '',
        link: ''
      },
      editRules: {
        type: [
          { required: true, message: '请选择归属板块', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传广告图', trigger: 'blur' }
        ]
      },
      // 编辑弹窗
      editVisible: false
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/ad-list')
      if (res.code === 200) {
        this.pageData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 编辑
    async edit (id) {
      const { data: res } = await this.$http.get('ad', { params: { id: id } })
      if (res.code === 200) {
        this.editForm.id = res.data.id
        this.editForm.sort = res.data.sort
        this.editForm.type = res.data.type
        this.editForm.img = res.data.img
        this.editForm.link = res.data.link
        this.editVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    checkedAddFiles (data) {
      this.addForm[data.filed] = data.url
    },
    checkedEditFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delAddSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    },
    // 删除已选择文件
    delEditSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    },
    // 提交添加表单
    addSubmit () {
      this.$refs.addRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/ad', this.addForm)
          if (res.code === 200) {
            this.$refs.addRef.resetFields()
            await this.getPageData()
            this.addVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    // 提交编辑表单
    editSubmit () {
      this.$refs.editRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/ad', this.editForm)
          if (res.code === 200) {
            await this.getPageData()
            this.editVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    cancellAdd () {
      this.$refs.addRef.resetFields()
      this.addVisible = false
    },
    cancellEdit () {
      this.$refs.editRef.resetFields()
      this.editVisible = false
    },
    // 删除广告
    async del (id) {
      const { data: res } = await this.$http.delete('/ad', { params: { id: id } })
      if (res.code === 200) {
        await this.getPageData()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭添加弹窗
    addClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.addRef.resetFields()
          done()
        })
        .catch(_ => {})
    },
    // 关闭编辑弹窗
    editClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.editRef.resetFields()
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>

<style scoped>
.page-content{
  margin-top: 30px;
}
.table-area{
  margin-top: 30px;
}
</style>
